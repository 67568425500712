<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <slot name="title" />

      <SearchField
        :show-advanced-search="showAdvancedSearch"
        :field-options="fieldOptions"
        :is-searching="isSearching"
        @input="$emit('input:search', $event)"
        @click:search="$emit('click:search', $event)"
        @click:export="$emit('click:export', $event)"
        @update:showAdvancedSearch="showAdvancedSearch = $event"
      >
        <VButton
          v-if="currentRoute.name !== `${route}-create`"
          :label="label"
          class="btn-primary ml-3"
          @click="onClickCreate"
        />
      </SearchField>
    </div>

    <AdvancedSearch
      v-if="showAdvancedSearch"
      :show-advanced-search="showAdvancedSearch"
      :field-options="fieldOptions"
      :is-searching="isSearching"
      :class="{ 'mb-10': showAdvancedSearch }"
      @click:search="onClickSearch($event)"
      @click:export="onClickExport"
    >
      <template #content="{ index, filters }">
        <slot name="filters" :index="index" :filters="filters" />
      </template>
    </AdvancedSearch>

    <slot>
      <router-view />
    </slot>
  </div>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// Components
import SearchField from "@/components/SearchField";
import VButton from "@/components/VButton";
import AdvancedSearch from "@/components/AdvancedSearch.vue";

export default {
  components: {
    SearchField,
    VButton,
    AdvancedSearch
  },
  props: {
    fieldOptions: {
      type: Array,
      default: () => []
    },
    isSearching: {
      type: Boolean,
      default: false
    },
    route: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    }
  },
  emits: ["input:search", "click:search", "click:export"],
  setup(props) {
    // Misc
    const router = useRouter();
    const currentRoute = useRoute();

    // DATA
    const showAdvancedSearch = ref(false);

    // Methods
    const onClickCreate = () => {
      router.push({ name: `${props.route}-create` });
    };

    return {
      currentRoute,
      onClickCreate,
      showAdvancedSearch
    };
  }
};
</script>
