<template>
  <QuizView>
    <VHeader>
      <template #avatar>
        <VImage
          :name="getText(data?.texts, 'name')"
          :color="data?.colour"
          :width="12"
          :height="12"
          class="items-center justify-center"
        />
      </template>

      <template #title>
        {{ getText(data?.texts, "name") }}
      </template>

      <template #subtitle>
        <div class="version">{{ $t("app.versions") }} {{ data?.version }}</div>
      </template>

      <template #status>
        <VChip
          v-if="data?.status"
          :text="$t(`app.${data?.status}`)"
          :class="getStatusColor(data?.status)"
        />
      </template>

      <template #tabs>
        <div class="flex justify-between">
          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(0, -1)"
            @click="onClickRedirect"
          />

          <VTabs
            :current-tab="currentTab"
            :tabs="tabs.slice(-1)"
            @click="onClickRedirect"
          />
        </div>
      </template>
    </VHeader>

    <div v-if="isRefreshing" class="flex justify-center">
      <LoadingIcon icon="three-dots" class="w-8 h-8" />
    </div>

    <router-view
      v-else
      :id="id"
      :key="id"
      :resource="data"
      :resource-value="resourceValue"
      :rules="rules"
      :options="options"
      :is-loading="isLoading"
      @click:cancel="onClickCancel"
      @click:save="onClickSave"
      @refresh="getData"
    />
  </QuizView>
</template>

<script>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
// Composables
import useQuiz from "@/composables/useQuiz";
import useUpdate from "@/composables/useUpdate";
import useTexts from "@/composables/useTexts";
import useColor from "@/composables/useColor";
import useTeachingLogs from "@/composables/useTeachingLogs";
import useTabs from "@/composables/useTabs";
// Components
import QuizView from "./QuizView";
import VHeader from "@/components/VHeader";
import VTabs from "@/components/VTabs";
import VChip from "@/components/VChip";
import VImage from "@/components/VImage";

export default {
  components: {
    QuizView,
    VHeader,
    VTabs,
    VChip,
    VImage
  },
  props: {
    id: {
      type: [String, Number],
      required: true
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const route = useRoute();

    // Data
    const isRefreshing = ref(false);

    // Composables
    const { buildTab } = useTabs();
    const { isAuthorized } = useTeachingLogs();
    const { getStatusColor } = useColor();
    const { getText } = useTexts();
    const { endpoint, route: baseRoute, value, rules, options } = useQuiz();
    const { data, onClickCancel, onClickSave, isLoading, getData } = useUpdate({
      endpoint,
      route: baseRoute,
      id: props.id,
      relations: ["texts", "instructors", "competencies", "tags"]
    });

    // Computed
    const currentTab = computed(() => {
      switch (route.name) {
        case `${baseRoute}-overview`:
          return "overview";
        case `${baseRoute}-details`:
          return "details";
        case `${baseRoute}-usage`:
          return "usage";
        case `${baseRoute}-attempts`:
          return "attempts";
        case `${baseRoute}-preview`:
          return "preview";
        case `${baseRoute}-feedback`:
          return "feedback";
        case `${baseRoute}-logs`:
          return "logs";
        default:
          return "";
      }
    });

    const tabs = computed(() => {
      return [
        buildTab({ name: "overview" }),
        buildTab({ name: "details" }),
        buildTab({ name: "preview" }),
        buildTab({ name: "usage" }),
        buildTab({ name: "feedback" }),
        buildTab({ name: "attempts" }),
        buildTab({
          name: "logs",
          disabled: !isAuthorized.value
        })
      ];
    });

    // Methods
    const onClickRedirect = tab => {
      router.push({
        name: `${baseRoute}-${tab}`,
        params: {
          id: props.id
        }
      });
    };

    const refresh = async () => {
      isRefreshing.value = true;
      await getData();
      isRefreshing.value = false;
    };

    return {
      refresh,
      isRefreshing,
      onClickRedirect,
      tabs,
      currentTab,
      // useQuiz
      resourceValue: value,
      rules,
      options,
      // useColor
      getStatusColor,
      // useUpdate
      data,
      onClickCancel,
      onClickSave,
      isLoading,
      getData,
      // useTexts,
      getText
    };
  }
};
</script>
